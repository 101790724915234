<template>
    <div class="animate__animated animate__fadeInUp">
        <v-card-title>{{$t("support.mailsupport.title")}}</v-card-title>

        <v-form v-model="valid" ref="r_form_mailsupport">
            <v-select v-model="support_category"
                      :label="$t('support.mailsupport.kind-of-support')"
                      :items="$variables.v.support_categories"
                      item-value="id"
                      :color="$variables.v.theme.secondary"
                      single-line
                      outlined>
                <template slot=selection slot-scope="data">
                    {{$t(data.item.text)}}
                </template>
                <template slot=item slot-scope="data">
                    {{$t(data.item.text)}}
                </template>
            </v-select>

            <div v-if="support_category">

                <v-card-text>{{$t("support.mailsupport.out-support-language")}}</v-card-text>

                <v-text-field
                        v-model="support_email"
                        :color="$variables.v.theme.secondary"
                        :label="$t('support.mailsupport.mail')"
                        :rules="[rules.required, rules.valid_email]"
                        outlined
                        maxlength="300"></v-text-field>

                <v-text-field
                        v-model="support_subject"
                        :color="$variables.v.theme.secondary"
                        :label="$t('support.mailsupport.subject')"
                        :rules="[rules.required, rules.min5, rules.max50]"
                        outlined
                        counter
                        maxlength="50"></v-text-field>

                <v-textarea v-model="support_message"
                            :color="$variables.v.theme.secondary"
                            :label="$t('support.mailsupport.message')"
                            :rules="[rules.required, rules.min10, rules.max3000]"
                            counter
                            outlined
                            auto-grow
                            no-resize
                            rows="3"
                            maxlength="3000"></v-textarea>

                <v-alert dense
                         outlined
                         type="error">
                    <div class="mt-3 mb-10"><strong>{{$t("support.mailsupport.info.abuse")}}</strong></div>

                    <div v-if="support_category === 1">
                        <div class="mt-3">{{$t("support.mailsupport.info.report-user1")}}</div>
                        <div class="mt-3">{{$t("support.mailsupport.info.report-user2")}}</div>
                        <div class="my-3">{{$t("support.mailsupport.info.report-user3")}}</div>
                    </div>

                    <div v-if="support_category === 2">
                        <div class="mt-3">{{$t("support.mailsupport.info.report-bug1")}}</div>
                        <div class="mt-3">{{$t("support.mailsupport.info.report-bug2")}}</div>
                        <div class="my-3">{{$t("support.mailsupport.info.report-bug3")}}</div>
                    </div>

                    <div v-if="support_category === 3">
                        <div class="my-3">{{$t("support.mailsupport.info.need-help")}}</div>
                    </div>

                    <div v-if="support_category === 4">
                        <div class="mt-3">{{$t('support.mailsupport.info.feedback1')}}</div>
                        <div class="my-3">{{$t('support.mailsupport.info.feedback2')}}</div>
                    </div>

                    <div v-if="support_category === 5">
                        <div class="my-3">{{$t("support.mailsupport.info.i-want-donate")}}</div>
                    </div>
                </v-alert>

                <v-col cols="12" class="text-center">
                    <vue-hcaptcha v-if="!$variables.v.debug_disable_captcha"
                                  :key="unlock_captcha"
                                  :sitekey="$variables.v.hcaptcha_sitekey"
                                  @verify="captcha_success=true"
                                  @expired="captcha_success=false"
                                  @error="captcha_success=false"
                                  theme="dark"></vue-hcaptcha>
                </v-col>

                <div class="text-center mt-10">
                    <v-btn :disabled="!valid || (!$variables.v.debug_disable_captcha && !captcha_success)"
                           depressed
                           :color="$variables.v.theme.primary"
                           @click="send_inquiry">{{$t("support.mailsupport.send-message")}}
                    </v-btn>
                </div>
            </div>
        </v-form>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                valid           : false,
                captcha_success : false,
                unlock_captcha  : 0,
                support_email   : "",
                support_subject : "",
                support_message : "",
                support_category: "",
                rules           : {
                    required   : v => (!!v) || this.$t("rules.required"),
                    valid_email: v => (v !== undefined && /.+@.+\..+/.test(v)) || this.$t("rules.email-must-valid"),
                    min5       : v => (v !== undefined && v.length >= 5) || this.$t("rules.min-5-chars"),
                    min10      : v => (v !== undefined && v.length >= 10) || this.$t("rules.min-10-chars"),
                    max50      : v => (v !== undefined && v.length <= 50) || this.$t("rules.max-50-chars"),
                    max3000    : v => (v !== undefined && v.length <= 3000) || this.$t("rules.max-3000-chars"),
                },
            }
        },
        created() {
            this.support_email = this.$user.user.email ? this.$user.user.email : "";
        },
        methods: {
            async send_inquiry() {
                if (this.$refs.r_form_mailsupport.validate()) {
                    let response = await this.func.ax("/php/mail_support.php", this.build_postdata());
                    await this.$root.check_response(response, async () => {
                        this.eventbus.$emit("SB", "support.mailsupport.msg.success", "success", 30);
                        this.$refs.r_form_mailsupport.reset();
                        await this.$router.push(this.$variables.v.navigation.support);
                    }, "support.mailsupport.msg.error");
                }
                this.unlock_captcha++;
            },
            build_postdata() {
                return {
                    userid      : this.$user.user.auth ? this.$user.user.id : null,
                    token       : this.$user.user.auth ? this.$user.user.token : null,
                    language    : this.$lang,
                    category    : this.support_category,
                    email       : this.support_email,
                    subject     : this.support_subject,
                    message     : this.support_message,
                    message_html: this.support_message.replace(/\n/g, "<br>"),
                };
            },
        },
        metaInfo() {
            return {
                title: `${this.$t('meta.page-title-unformated')} - ${this.$t('meta.page-title-mailsupport')}`,
                meta : [{
                    name   : "description",
                    content: this.$t("meta.description"),
                }]
            }
        },
    }

</script>